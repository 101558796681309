import React, { Component } from "react"

/* Alicorn ep-ui */
import { Background, Balance, Notification, Internet, InactionTiming, GameInfo, Transactions, Error } from "@alicorn/ep-ui"

/* Widgets */
import { PlayerCards, DealerCards, Rates, Chips, PokerResult, Preloader } from './widgets'

/* Components */
import { Fold, Bet, Start, Payout, Open, Picture } from './components'
import { Jackpot, BonusWin } from "@alicorn/ep-ui"

/* Socket IO */
import { io } from "socket.io-client"

/* JWT */
import { decodeToken } from 'react-jwt'

/* REST API */
import { history } from './api/History'

/* Game quit alicorn */
import { setIframeMessageSenderSettings, useSendInitIframeMessage, postMessage } from '@alicorn/iframe-message-sender'

/* Constants */
import { env, utils, sound } from "./constants"
import { ERROR, CHOICE, GAME, LOADING } from "./constants/status"

/* Rules */
import { rules } from "./constants/rules"

/* Redux */
import { connect } from 'react-redux'

/* Game */
import Winner from './game/Winner'

/* Fields */
const BOXES = [0, 1]
const pathname = window.location.search
const token = pathname.length > 1 ? pathname.substring(1) : ""
const imgUrl = 'https://repb.rekopgames.com/images/rules/game.png'

/* FRAME SETTINGS */
setIframeMessageSenderSettings({ parentName: 'makao-front', sourceName: 'poker', isChild: true })


/* Entry Point */
class App extends Component {

    constructor() {
        super()

        const data = decodeToken(token)

        this.state = {
            status: LOADING,

            ante: [0, 0],
            bonus: [0, 0],
            selected: 2,
            last: [{ ante: 0, bonus: 0 }, { ante: 0, bonus: 0 }],
            bet: [0, 0],
            list: [],
            balance: 0,
            total: 0,
            lastWin: 0,

            solution: ["", ""],
            open: [false, false],

            playerCards: [[], []],
            playerGame: [null, null],

            dealerCards: [],
            dealerGame: null,
            openGame: null,

            result: [null, null],
            bonusResult: [null, null],
            chip: [false, false],

            gameInfo: null,
            transactions: [],
            operations: [],
            histories: [],
            historyLoading: true,
            historyPage: 1,
            historyHasMore: true,

            showInternetConnectionError: 0,

            /* Game Session */
            closed: false,
            newDevice: false,
            isDisconnected: false,

            currency: data.currency,
            chips: utils.getChips(data.chips),
            game: {
                max: data.max,
                maxBonus: data.maxBonus,
                minBonus: data.minBonus,
                min: data.min,
                maxPay: data.maxPay
            },
            operator: data.operator,

            volume: false,
            locale: data.locale ? data.locale : "ru",

            withJackpot: data.withJackpot,
            jackpot: null,
            bonusWin: []
        }

        /* Start connection */
        this.socket = io(env.endpoint, { auth: { token: `${token}`, reconnection: false, isPlayer: true } })
        this._bonusTiming = null
    }

    componentDidMount = () => {

        const { locale } = this.state
        const { setLanguage } = this.props
        setLanguage(locale)

        this.game()
        this.loadHistory()
    }

    /* Load History */
    loadHistory = (page = 1) => {

        const { histories } = this.state

        if (page > 1) {
            this.setState({ historyPage: page })
        }

        history(token, page).then(response => {
            if (response.status === 200) {
                let list = response.data

                if (page > 1) {
                    list = [...histories, ...response.data]
                }

                if (response.data.length < 5) {
                    this.setState({ historyHasMore: false })
                }

                this.setState({ histories: list, historyLoading: false })
            }
        }).catch(() => {
            this.setState({ histories: [], historyLoading: false })
        })
    }


    game = () => {
        /* On error */
        this.socket.on("connect_error", () => {

            const timer = setTimeout(() => {
                this.setState({ status: ERROR })
                clearTimeout(timer)
            }, 1000)

        })

        /*  */
        this.socket.on("disconnect", () => {
            this.setState({ isDisconnected: true })
        })

        /* On connect */
        this.socket.on("connect", () => {
            const timer = setTimeout(() => {
                if (this.state.status !== CHOICE) {
                    this.setState({ status: CHOICE })
                    this.clear()
                }
                clearTimeout(timer)
            }, 2000)
        })

        /* On New Device */
        this.socket.on("newDeviceConnection", () => {
            this.setState({ newDevice: true })
            this.socket.disconnect()
        })


        /* Reconnection */
        this.socket.on("reconnection", data => {

            const time = data.transactionError ? 3500 : 2000

            if (data.transactionError) {
                utils.toggleError(utils.translate("Transaction error"))
            }

            setTimeout(() => {
                this.setState({
                    status: data.status,
                    gameInfo: data.gameInfo,
                    ante: data.ante,
                    bonus: data.bonus,
                    total: data.total,
                    bet: data.bet,
                    open: data.open,
                    playerCards: data.playerCards,
                    dealerCards: data.dealerCards,
                    playerGame: data.playerGame,
                    dealerGame: data.dealerGame,
                    bonusResult: data.bonusResult,
                    result: data.result,
                    transactions: data.transactions,
                    solution: data.solution,
                    openGame: data.openGame
                })
            }, time)
        })

        /* On start */
        this.socket.on("status", data => {
            const { status } = this.state
            if (data !== status) {
                this.setState({ status: data })
            }

            if (data === CHOICE) {
                this.clear()
                this.loadHistory()
            }
        })


        /* Balance */
        this.socket.on("balance", data => {
            this.setState({ balance: data })
        })

        /* Get a dealer card */
        this.socket.on("dealer", data => {

            const { card } = data

            if (card) {

                const { dealerCards, volume, open } = this.state
                const i = dealerCards.findIndex(e => e.uuid === card.uuid)
                let cards = dealerCards

                if (i === -1) {

                    cards.push(card)
                    this.setState({ dealerCards: cards })

                    if (volume) {
                        sound.play('card', 0.2)
                    }
                }
                else if (dealerCards[i].id && i !== 4 && utils.valueLength(open) === 0) {
                    let current = dealerCards[i]
                    cards.splice(i, 1)
                    cards.push(current)
                    this.setState({ dealerCards: cards })
                }
            }

        })

        this.socket.on("dealerData", data => {

            const { result } = this.state
            const { box } = data

            const { volume } = this.state
            result[box] = data.result

            this.setState({ dealerCards: data.dealerCards, dealerGame: data.dealerGame, result })

            if (data.result && (data.result.result === "win" || data.result.result === "draw")) {
                if (volume) {
                    sound.play('win', 0.2)
                }
            }
        })

        /* Get a Player card */
        this.socket.on("player", data => {

            const { card, box } = data
            const { playerCards, volume } = this.state

            if (card && playerCards[box]) {

                const i = playerCards[box].findIndex(e => e.uuid === card.uuid)

                if (i === -1) {

                    let cards = playerCards
                    cards[box].push(card)

                    this.setState({ playerCards: cards })

                    if (volume) {
                        sound.play('card', 0.2)
                    }
                }

            }
        })

        this.socket.on("playerGame", data => {

            const { playerGame } = this.state
            const { hand, box } = data

            if (!playerGame[box]) {
                playerGame[box] = hand
                this.setState({ playerGame, status: GAME })
            }

        })


        /* OPEN */
        this.socket.on("open", ({ box, openCards, openGame }) => {
            const { open, solution } = this.state
            open[box] = true
            solution[box] = ""
            this.setState({ open, dealerCards: openCards, openGame, solution })
        })


        /* Result */
        this.socket.on("bonusResult", data => {

            const { result, box } = data
            const { bonusResult, bonusWin, volume } = this.state

            if (result) {

                bonusResult[box] = result
                bonusWin[box] = { ...result, box }
                this.setState({ bonusResult, bonusWin })

                /* Win */
                if (result.result === "win") {
                    if (volume) {
                        sound.play('winsmall', 0.2)
                    }
                }

                /* Clear timing */
                if (this._bonusTiming) {
                    clearTimeout(this._bonusTiming)
                    this._bonusTiming = null
                }

                /* Set timing */
                this._bonusTiming = setTimeout(() => {
                    this.setState({ bonusWin: [] })
                    clearTimeout(this._bonusTiming)
                }, 5000)

            }

        })

        /* Result */
        this.socket.on("result", result => {

            const { volume } = this.state

            this.setState({ result })

            if (result && result.result === "win") {
                this.setState({ lastWin: result.sum })
            }

            if (result && (result.result === "win" || result.result === "draw")) {
                if (volume) {
                    sound.play('win', 0.2)
                }
            }
        })

        /* Get Game Info */
        this.socket.on("gameInfo", data => {
            this.setState({ gameInfo: data })
        })

        /* Get transactions list */
        this.socket.on("transaction", data => {
            if (data.id) {
                const { operations } = this.state

                if (data.type === "credit") {
                    const timer = setTimeout(() => {
                        this.setState({ lastWin: this.state.lastWin + parseFloat(data.total) })
                        clearTimeout(timer)
                    }, parseInt(data.box) * 1000)
                }

                operations.push(data)
                this.setState({ operations })
            }
        })

        this.socket.on("editTransaction", data => {
            const { operations } = this.state
            let list = operations
            const index = list.findIndex(e => e.number === data.number)
            if (index > -1) {
                list[index].status = data.status
                this.setState({ operations: list })
            }
        })


        /* Force End Game */
        this.socket.on("forceEnd", () => {
            this.setState({ status: CHOICE })
            this.loadHistory()
            this.clear()
            utils.toggleError(utils.translate("The game ended due to the administrator's initiative"), "warning")
        })

        /* Notification From Admin */
        this.socket.on("adminNotification", data => {
            if (data && data.title && data.total && data.currency) {
                utils.toggleError(`${utils.translate(data.title)} ${utils.getWithCurrency(data.total, data.currency)}`, "success")
                this.loadHistory()
            }
        })

        /* Jackpot */
        this.socket.on("jackpot", jackpot => {
            this.setState({ jackpot })
        })
    }

    /* Disconnect user when inaction timer equal to 0 */
    setInactionState = () => {
        this.setState({ closed: true })
        this.socket.disconnect()
        this.clear()
    }

    /* Reser top timing */
    resetTimer = () => {
        if (this._inaction) {
            this._inaction.reset()
        }
    }

    handleClose = (id) => {

        const { showInternetConnectionError } = this.state

        if (id === "internet" && showInternetConnectionError <= 2) {
            this.setState({ showInternetConnectionError: showInternetConnectionError + 1 })
            if (showInternetConnectionError + 1 === 2) {
                setTimeout(() => {
                    this.setState({ showInternetConnectionError: 1 })
                }, 300000)
            }
        }

    }

    /* Check internet speed */
    checkInternetSpeed = (speed) => {

        const { showInternetConnectionError } = this.state

        if (showInternetConnectionError < 2) {
            if (parseInt(speed) < 3) {
                utils.toggleError(utils.translate("Poor internet connection"), "warning", "internet", this.handleClose)
            }
            else {
                utils.toastDismiss('internet')
            }
        }

    }

    clear = () => {
        this.setState({
            ante: [0, 0],
            bonus: [0, 0],
            bet: [0, 0],
            total: 0,
            list: [],
            gameInfo: null,
            solution: ["", ""],
            open: [false, false],
            playerCards: [[], []],
            playerGame: [],
            dealerCards: [],
            dealerGame: null,
            openGame: null,
            result: [],
            bonusResult: [],
            operations: []
        })
    }

    /* STAKE RATE */
    setStake = (data) => {

        const { ante, bonus } = data

        let total = 0

        ante.forEach(item => {
            if (item) {
                total += item
            }
        })

        bonus.forEach(item => {
            if (item) {
                total += item
            }
        })

        this.setState({ ante, bonus, total, lastWin: 0 })
        this.resetTimer()
    }


    /* START ACTION */
    start = () => {

        const { ante, bonus, game, currency } = this.state

        if (game.minBonus) {
            for (const item of bonus) {
                if (item > 0) {
                    if (parseInt(game.minBonus) > item) {
                        utils.toggleError(`${utils.translate("Minimum bid for bonus")} ${utils.getWithCurrency(game.minBonus, currency)}`, "error", "maxbidbonus")
                        return
                    }
                }
            }
        }

        let stake = []
        ante.forEach((item, index) => {
            if (item) {
                stake.push({ ante: item, bonus: utils.check(bonus[index], 0) })
            }
            else {
                stake.push({ ante: 0, bonus: 0 })
            }
        })

        if (utils.valueLength(ante) > 0) {
            this.setState({ status: "DEALING", last: stake })
            this.socket.emit('start', { ante, bonus })
        }

        this.resetTimer()
    }


    /* FOLD ACTION */
    fold = box => {

        const { dealerCards, solution } = this.state

        if (dealerCards.length === 5 && solution[box] !== "BET" && solution[box] !== "FOLD") {
            this.socket.emit("pass", box)
            solution[box] = "FOLD"
            this.setState({ solution })
        }

        this.resetTimer()
    }

    /* BET ACTION */
    bet = box => {

        const { dealerCards, ante, bet, total, solution, volume } = this.state

        if (dealerCards.length === 5 && solution[box] !== "BET" && solution[box] !== "FOLD") {

            this.socket.emit("bet", box)
            solution[box] = "BET"
            const value = ante[box] * 2
            bet[box] = value

            this.setState({ solution, bet, total: total + value })

            if (volume) {
                sound.play('sound', 0.2)
            }

        }

        this.resetTimer()
    }


    /* OPEN ACTION */
    open = box => {

        const { dealerCards, solution, chip, ante, total, volume } = this.state

        if (dealerCards.length === 5 && solution[box] !== "BET" && solution[box] !== "FOLD") {
            this.socket.emit("open", box)
            solution[box] = "OPEN"
            chip[box] = true
            const value = ante[box] / 2

            this.setState({ solution, chip, total: total + value })

            const timer = setTimeout(() => {
                const data = this.state.chip
                data[box] = false
                this.setState({ chip: data })
                clearTimeout(timer)
            }, 2000)

            if (volume) {
                sound.play('sound', 0.2)
            }
        }

        this.resetTimer()
    }


    /* Draw Button */
    _buttons = box => {

        const { status, playerCards, dealerCards, solution, open, playerGame, openGame } = this.state

        if (status === "GAME" && dealerCards.length === 5 && playerCards[box].length >= 5 && solution[box] === "") {

            let disabled = false
            if (playerGame[box] && openGame) {
                const winner = new Winner()
                const result = winner.play(playerGame[box], openGame)
                if (result === "no") {
                    disabled = true
                }
            }

            return (
                <div className={`buttons-box box-${box} ${open[box] ? 'two-button' : 'three-button'}`}>
                    <Fold onClick={() => this.fold(box)} />
                    <Bet disabled={disabled} onClick={() => this.bet(box)} />
                    {open[box] === false && <Open onClick={() => this.open(box)} />}
                </div>
            )
        }
    }

    /* Draw Start Button */
    _start = () => {

        const { ante, status } = this.state

        if (status === "CHOICE") {
            return (
                <div className="start-button-box">
                    <Start onClick={() => this.start()} ante={ante} />
                </div>
            )
        }

    }

    /* Box Result */
    renderResult = (box) => {
        const { result, currency } = this.state
        return <PokerResult key={`${box}`} box={box} result={result[box]} currency={currency} />
    }

    /* Draw Content */
    _content = () => {

        /* Fields */
        const { status, withJackpot, ante, bonus, selected, last, list, playerCards, playerGame, dealerCards, dealerGame, openGame, solution, balance, game, chips, currency, volume, open, result, bonusResult, bet, chip } = this.state

        const style = utils.valueLength(ante) === 1 && status !== CHOICE ? "one-box" : ""

        const gameJackpot = withJackpot === "yes"

        return (
            <div className="content">

                <div className={`game ${style} ${status !== "CHOICE" ? "visible" : "hidden"}`}>

                    <DealerCards
                        cards={dealerCards}
                        game={dealerGame}
                        open={open}
                        solution={solution}
                        ante={ante}
                        openGame={openGame}
                    />

                    <div className={`box-container ${style} ${status === "CHOICE" ? "bottom" : "default"} ${gameJackpot ? "" : "centered"}`}>
                        {BOXES.map(box => {
                            if (status === CHOICE || ante[box]) {
                                return (
                                    <div className="box" key={`${box}`}>

                                        <PlayerCards
                                            box={box}
                                            ante={ante[box]}
                                            status={status}
                                            cards={playerCards[box]}
                                            game={playerGame[box]}
                                            solution={solution[box]}
                                            renderResult={() => this.renderResult(box)}
                                        />

                                        <Rates
                                            box={box}
                                            chips={chips}
                                            status={status}
                                            ante={ante[box]}
                                            bonus={bonus[box]}
                                            result={result[box]}
                                            bonusResult={bonusResult[box]}
                                            bet={bet[box]}
                                            setStake={data => this.setStake(data)}
                                            selected={selected}
                                            list={list}
                                            setList={list => this.setState({ list })}
                                            solution={solution[box]}
                                            balance={balance}
                                            chip={chip[box]}
                                            bottomBet={() => this.bet(box)}
                                            game={game}
                                            currency={currency}
                                            volume={volume}
                                            all={{ ante, bonus }}
                                            gameJackpot={gameJackpot}
                                        />

                                        {this._buttons(box)}

                                    </div>
                                )
                            }
                            return null
                        }
                        )}
                    </div>

                    {this._start()}

                </div>

                <div className={`choice ${status === "CHOICE" ? "visible" : "hidden"}`}>
                    <Chips
                        box={0}
                        chips={chips}
                        ante={ante}
                        bonus={bonus}
                        setStake={data => this.setStake(data)}
                        last={last}
                        selected={selected}
                        setSelected={selected => this.setState({ selected })}
                        list={list}
                        setList={list => this.setState({ list })}
                        balance={balance}
                        game={game}
                        currency={currency}
                        volume={volume}
                    />
                </div>

            </div>
        )
    }


    /* Get Rules */
    getRules = () => {

        const { language } = this.props

        if (rules[language]) {
            return rules[language]
        }

        return rules["ru"]
    }

    /* Game action */
    gameAction = (action) => {

        const { operator } = this.state

        if (operator !== "makao") {
            this.socket.emit("gameAction", action)
        }
        else {
            postMessage({ type: action })
        }

    }

    render = () => {

        const { status, balance, total, lastWin, currency, game, histories, historyHasMore, historyPage, operations, closed, newDevice, isDisconnected, gameInfo, volume, locale, jackpot, bonusWin, withJackpot, operator } = this.state
        const { language, setLanguage } = this.props


        const gameJackpot = withJackpot === "yes"
        const payout = <Payout game={game} currency={currency} gameJackpot={gameJackpot} />
        const historyData = { histories, historyHasMore }
        const params = { mediapoint: `${env.mediapoint}`, type: "two-box", lang: locale, operator }

        let newrule = this.getRules()
        if (!gameJackpot) {
            newrule = newrule.filter(e => e.type !== "bonus")
        }

        if (status === LOADING) {
            return <Preloader />
        }

        if (status === ERROR || newDevice || closed || isDisconnected) {
            return <Error newDevice={newDevice} closed={closed} isDisconnected={isDisconnected} params={params} />
        }

        return (
            <Background params={params} gameJackpot={gameJackpot} language={language} setLanguage={language => setLanguage(language)} historyData={historyData} payout={payout} gameInfo={gameInfo} rules={newrule} openBalance={() => this.gameAction("openDepositModal")} loadMore={() => this.loadHistory(historyPage + 1)} volume={volume} setVolume={volume => this.setState({ volume })}>
                <div className="app">

                    {(bonusWin.length > 0 && gameJackpot) && <BonusWin data={bonusWin} currency={currency} />}

                    <InactionTiming time={240} ref={ref => this._inaction = ref} setInactionState={() => this.setInactionState()} />

                    <GameInfo game={game} currency={currency} payout={payout} params={params} close={() => this.gameAction("closeGame")} />
                    <Balance status={status} balance={balance} total={total} lastWin={lastWin} currency={currency} params={params} openBalance={() => this.gameAction("openDepositModal")} />
                    <Transactions info={gameInfo} operations={operations} currency={currency} params={params} />
                    {gameJackpot && <Jackpot jackpot={jackpot} />}

                    {this._content()}

                    <div className="back-outer">
                        <div className="background" />
                        <div className="glass" />
                        <div className="shadow" />
                    </div>

                    <div className={`makao ${(status === CHOICE) ? 'visible' : 'hidden'}`}>
                        <Picture src={`${env.mediapoint}/images/cubago.png`} alt="Makao" />
                    </div>

                    <Notification />

                    <HookWrapper />

                    <Internet url={imgUrl} setInternetSpeed={data => this.checkInternetSpeed(data)} />

                </div>
            </Background>
        )
    }

}
const mapStateToProps = state => {
    return {
        language: state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLanguage: data => dispatch({ type: 'SET_LANGUAGE', payload: data })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

function HookWrapper() {
    useSendInitIframeMessage()
    return null
}